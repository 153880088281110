const CONF = {
    "environment": "production",
    "serverName": "geosurvey.qed.ai",
    "release": "883cc6d6a6c4e0351588223b5f716a2aab8c5b8b",
    "whitelistUrls": [
        "geosurvey.qed.ai"
    ]
};


const DSN = "https://8838f098da754489b49da70edbd1fdeb@o38036.ingest.sentry.io/87185";

module.exports = {
    "CONF": CONF,
    "DSN": DSN
};
