import '@babel/polyfill';
import 'whatwg-fetch';
import * as Sentry from '@sentry/browser';

import { DSN, CONF } from '../../../raven-conf';

Sentry.init({
  dsn: DSN,
  release: CONF.release,
  environment: CONF.environment,
  attachStacktrace: true,
  serverName: CONF.serverName
});

window.onunhandledrejection = e => Sentry.captureException(e.reason);
